import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ENV } from '@/config/env';

// Improved type definitions for Google Analytics
declare global {
  interface Window {
    dataLayer: Array<any>;
    gtag: (...args: [string, string, Record<string, any>] | [string, Date] | any[]) => void;
  }
}

interface GtagConfig {
  page_path: string;
  page_title?: string;
  send_page_view?: boolean;
}

const GA_MEASUREMENT_ID = ENV.GA_MEASUREMENT_ID;
const IS_PRODUCTION = ENV.IS_PRODUCTION;

export default function GoogleAnalytics() {
  const location = useLocation();

  useEffect(() => {
    if (!GA_MEASUREMENT_ID || !IS_PRODUCTION) {
      console.warn('Google Analytics disabled: Missing tracking ID or not production');
      return;
    }

    // Initialize GA if not already done
    if (!window.gtag) {
      window.dataLayer = window.dataLayer || [];
      function gtag(...args: Parameters<typeof window.gtag>) {
        window.dataLayer.push(args);
      }
      window.gtag = gtag;
    }

    // Track page view
    try {
      window.gtag('event', 'page_view', {
        page_location: window.location.href,
        page_path: location.pathname + location.search,
        page_title: document.title,
        send_to: GA_MEASUREMENT_ID
      });
    } catch (error) {
      console.error('Error tracking page view:', error);
    }
  }, [location]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (window.gtag && IS_PRODUCTION) {
        window.gtag('config', GA_MEASUREMENT_ID, {
          send_page_view: false
        });
      }
    };
  }, []);

  return null;
} 