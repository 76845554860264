import { Star, Clock, MapPin, ExternalLink } from 'lucide-react';
import { cn } from '../lib/utils';
import type { AmadeusActivity } from '../services/amadeus';

interface ActivityCardProps {
  activity: AmadeusActivity;
  compact?: boolean;
  className?: string;
  onClick?: () => void;
}

export function ActivityCard({ 
  activity, 
  compact = false,
  className,
  onClick 
}: ActivityCardProps) {
  // Parse coordinates to numbers for formatting
  const latitude = parseFloat(activity.geoCode.latitude);
  const longitude = parseFloat(activity.geoCode.longitude);

  return (
    <div 
      className={cn(
        "bg-dark-gray rounded-lg overflow-hidden",
        "border border-gray-800 hover:border-gray-700 transition-colors",
        onClick && "cursor-pointer",
        className
      )}
      onClick={onClick}
    >
      {activity.pictures?.[0] && (
        <div className={cn(
          "relative overflow-hidden",
          compact ? "h-32" : "h-48"
        )}>
          <img 
            src={activity.pictures[0]} 
            alt={activity.name}
            className="w-full h-full object-cover"
          />
          {activity.price && (
            <div className="absolute top-2 right-2 bg-primary text-black px-2 py-1 rounded-full text-xs font-medium">
              {activity.price.amount} {activity.price.currencyCode}
            </div>
          )}
        </div>
      )}
      
      <div className="p-4">
        <h4 className={cn(
          "font-medium line-clamp-2",
          compact ? "text-base mb-2" : "text-lg mb-3"
        )}>
          {activity.name}
        </h4>
        
        <div className={cn(
          "flex items-center gap-4 text-gray-400",
          compact ? "text-sm" : "text-base"
        )}>
          {activity.rating && (
            <div className="flex items-center">
              <Star className="w-4 h-4 text-yellow-500 mr-1" />
              {activity.rating}
            </div>
          )}
          {activity.minimumDuration && (
            <div className="flex items-center">
              <Clock className="w-4 h-4 mr-1" />
              {activity.minimumDuration}
            </div>
          )}
          <div className="flex items-center">
            <MapPin className="w-4 h-4 mr-1" />
            {!isNaN(latitude) && !isNaN(longitude) ? (
              `${latitude.toFixed(2)}, ${longitude.toFixed(2)}`
            ) : (
              'Location available'
            )}
          </div>
        </div>
        
        {!compact && (
          <>
            <p className="mt-3 text-sm text-gray-300 line-clamp-2">
              {activity.shortDescription}
            </p>
            
            {activity.bookingLink && (
              <a
                href={activity.bookingLink}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-4 inline-flex items-center text-sm text-primary hover:text-primary-hover"
              >
                Book Now
                <ExternalLink className="w-4 h-4 ml-1" />
              </a>
            )}
          </>
        )}
      </div>
    </div>
  );
} 