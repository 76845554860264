import { useRef, useEffect, useState } from 'react';
import { useChat } from '../../contexts/ChatContext';
import { Message } from '../../types/chat';
import { Copy, ThumbsUp, Check, MapPin, Star, Clock, DollarSign } from 'lucide-react';
import { cn } from '../../lib/utils';
import ReactMarkdown from 'react-markdown';
import type { ComponentPropsWithoutRef } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import type { AmadeusActivity } from '../../services/amadeus';

interface ChatMessagesProps {
  messagesEndRef: React.RefObject<HTMLDivElement>;
  onScrollNeeded: (behavior: 'auto' | 'smooth') => void;
  activities?: AmadeusActivity[];
  cityName?: string;
}

interface ActivityCardProps {
  activity: AmadeusActivity;
  compact?: boolean;
  onClick?: () => void;
}

function ActivityMessageCard({ activity, compact, onClick }: ActivityCardProps) {
  return (
    <div 
      className={cn(
        "bg-gray-800/50 rounded-lg overflow-hidden",
        "border border-gray-700/50 hover:border-gray-600/50 transition-colors",
        "cursor-pointer"
      )}
      onClick={onClick}
    >
      {activity.pictures?.[0] && (
        <div className="relative h-32 overflow-hidden">
          <img 
            src={activity.pictures[0]} 
            alt={activity.name}
            className="w-full h-full object-cover"
          />
          {activity.price && (
            <div className="absolute top-2 right-2 bg-primary text-black px-2 py-1 rounded-full text-xs font-medium">
              {activity.price.amount} {activity.price.currencyCode}
            </div>
          )}
        </div>
      )}
      
      <div className="p-4">
        <h4 className="font-medium mb-2 line-clamp-2">{activity.name}</h4>
        
        <div className="flex items-center gap-4 text-sm text-gray-400">
          {activity.rating && (
            <div className="flex items-center">
              <Star className="w-4 h-4 text-yellow-500 mr-1" />
              {activity.rating}
            </div>
          )}
          {activity.minimumDuration && (
            <div className="flex items-center">
              <Clock className="w-4 h-4 mr-1" />
              {activity.minimumDuration}
            </div>
          )}
        </div>
        
        {!compact && (
          <p className="mt-2 text-sm text-gray-300 line-clamp-2">
            {activity.shortDescription}
          </p>
        )}
      </div>
    </div>
  );
}

export default function ChatMessages({ 
  messagesEndRef, 
  onScrollNeeded,
  activities,
  cityName 
}: ChatMessagesProps) {
  const { state } = useChat();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [copiedId, setCopiedId] = useState<string | null>(null);

  // Auto-scroll handling
  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const shouldAutoScroll = 
      container.scrollHeight - container.scrollTop - container.clientHeight < 100;

    if (shouldAutoScroll) {
      onScrollNeeded('smooth');
    }
  }, [state.messages, onScrollNeeded]);

  return (
    <div 
      ref={scrollContainerRef}
      className="h-full overflow-y-auto overflow-x-hidden py-4 scroll-smooth"
    >
      <div className="flex flex-col space-y-4 px-4">
        {state.messages.map((message) => (
          <MessageBubble 
            key={message.id} 
            message={message}
            copiedId={copiedId}
            setCopiedId={setCopiedId}
            activities={activities}
            cityName={cityName}
          />
        ))}
        
        {state.isTyping && <TypingIndicator />}
        
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
}

function MessageBubble({ 
  message, 
  copiedId, 
  setCopiedId,
  activities,
  cityName
}: { 
  message: Message;
  copiedId: string | null;
  setCopiedId: (id: string | null) => void;
  activities?: AmadeusActivity[];
  cityName?: string;
}) {
  const isUser = message.role === 'user';
  const [showActivities, setShowActivities] = useState(false);

  const copyMessage = async () => {
    await navigator.clipboard.writeText(message.content);
    setCopiedId(message.id);
    setTimeout(() => setCopiedId(null), 2000);
  };

  // Check if message contains activity-related keywords
  const hasActivityContent = activities && cityName && !isUser && (
    message.content.toLowerCase().includes('activit') ||
    message.content.toLowerCase().includes('tour') ||
    message.content.toLowerCase().includes('experience')
  );

  return (
    <div className={cn(
      "group flex items-start gap-3",
      isUser ? "flex-row-reverse" : "flex-row"
    )}>
      <div className={cn(
        "flex-1 max-w-[80%] relative",
        "rounded-lg p-4",
        isUser 
          ? "bg-accent text-black" 
          : "bg-gray-800/50 text-gray-100"
      )}>
        <RichMessage content={message.content} />
        
        {/* Activity Preview */}
        {hasActivityContent && activities && activities.length > 0 && (
          <div className="mt-4 space-y-4">
            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-400">
                Related Activities in {cityName}
              </span>
              <button
                onClick={() => setShowActivities(!showActivities)}
                className="text-xs text-primary hover:text-primary/80"
              >
                {showActivities ? 'Show Less' : 'Show More'}
              </button>
            </div>
            
            {showActivities ? (
              <div className="grid grid-cols-1 gap-4">
                {activities.slice(0, 3).map(activity => (
                  <ActivityMessageCard
                    key={activity.id}
                    activity={activity}
                    compact
                  />
                ))}
              </div>
            ) : (
              <ActivityMessageCard
                activity={activities[0]}
                compact
              />
            )}
          </div>
        )}
        
        <MessageActions 
          message={message} 
          isUser={isUser}
          isCopied={copiedId === message.id}
          onCopy={copyMessage}
        />
      </div>
    </div>
  );
}

interface CodeBlockProps extends ComponentPropsWithoutRef<'code'> {
  inline?: boolean;
  children: React.ReactNode;
}

const CodeBlock = ({ className, children, inline, ...props }: CodeBlockProps) => {
  const match = /language-(\w+)/.exec(className || '');
  return !inline && match ? (
    <SyntaxHighlighter
      style={atomDark}
      language={match[1]}
      PreTag="div"
    >
      {String(children).replace(/\n$/, '')}
    </SyntaxHighlighter>
  ) : (
    <code className={className} {...props}>
      {children}
    </code>
  );
};

function RichMessage({ content }: { content: string }) {
  return (
    <div className="prose prose-invert max-w-none">
      <ReactMarkdown
        components={{
          code: CodeBlock as any
        }}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
}

function MessageActions({ 
  message, 
  isUser, 
  isCopied, 
  onCopy 
}: { 
  message: Message;
  isUser: boolean;
  isCopied: boolean;
  onCopy: () => void;
}) {
  return (
    <div className={cn(
      "absolute -top-2 opacity-0 group-hover:opacity-100 transition-opacity",
      isUser ? "left-2" : "right-2"
    )}>
      <div className="flex items-center gap-1 bg-gray-900 rounded-full p-1">
        <button
          onClick={onCopy}
          className="p-1 hover:bg-gray-700 rounded-full"
          title="Copy message"
        >
          {isCopied ? (
            <Check className="w-3 h-3 text-green-400" />
          ) : (
            <Copy className="w-3 h-3" />
          )}
        </button>
        {!isUser && (
          <button
            className="p-1 hover:bg-gray-700 rounded-full"
            title="Like message"
          >
            <ThumbsUp className="w-3 h-3" />
          </button>
        )}
      </div>
    </div>
  );
}

function TypingIndicator() {
  return (
    <div className="flex items-center space-x-2 p-4 bg-gray-800/50 rounded-lg max-w-[80%]">
      <div className="flex space-x-1">
        <span className="w-2 h-2 bg-white/70 rounded-full animate-bounce" style={{ animationDelay: '0ms' }} />
        <span className="w-2 h-2 bg-white/70 rounded-full animate-bounce" style={{ animationDelay: '150ms' }} />
        <span className="w-2 h-2 bg-white/70 rounded-full animate-bounce" style={{ animationDelay: '300ms' }} />
      </div>
    </div>
  );
} 