import { useEffect, useRef, useState } from 'react';
import { useChat } from '../../contexts/ChatContext';
import ChatHeader from './ChatHeader';
import ChatMessages from './ChatMessages';
import ChatInput from './ChatInput';
import { Loader2, MapPin, Star, Clock, DollarSign, Tag, X } from 'lucide-react';
import { cn } from '../../lib/utils';
import { QuotationService } from '../../services/QuotationService';
import type { AmadeusActivity } from '../../services/amadeus';
import { ActivityCard } from '../ActivityCard';

interface EnhancedChatInterfaceProps {
  activities?: AmadeusActivity[];
  cityName?: string;
}

interface QuickActionButtonProps {
  icon: React.ElementType;
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

function QuickActionButton({ icon: Icon, label, onClick, disabled }: QuickActionButtonProps) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={cn(
        "flex items-center gap-1.5 px-3 py-1.5 rounded-lg text-xs",
        "transition-colors whitespace-nowrap",
        disabled
          ? "bg-gray-800/50 text-gray-500 cursor-not-allowed"
          : "bg-gray-800 text-gray-300 hover:bg-gray-700"
      )}
    >
      <Icon className="w-3 h-3" />
      {label}
    </button>
  );
}

function WelcomeMessage({ cityName, activitiesCount }: { cityName?: string; activitiesCount?: number }) {
  return (
    <div className="absolute inset-0 flex items-center justify-center text-center p-4">
      <div className="space-y-4">
        <div className="w-12 h-12 rounded-full bg-accent/10 flex items-center justify-center mx-auto">
          <svg
            className="w-6 h-6 text-accent"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div className="space-y-3">
          <h3 className="text-lg font-medium text-white">
            Greetings, fellow adventurer!
          </h3>
          <div className="space-y-2">
            <p className="text-sm text-gray-300">
              I'm Marco, your personal travel companion, inspired by the legendary Marco Polo's spirit of exploration.
            </p>
            <p className="text-sm text-gray-400">
              Whether you seek hidden gems or luxurious escapes, I'm here to craft your perfect journey.
            </p>
          </div>
          
          {cityName && activitiesCount && (
            <div className="mt-4 p-4 bg-accent/5 rounded-lg">
              <p className="text-sm text-accent">
                I can help you explore {activitiesCount} activities in {cityName}!
              </p>
            </div>
          )}
          
          <div className="text-xs text-accent/80 pt-2">
            Try asking me:
            <ul className="mt-2 space-y-1.5 text-gray-400">
              {cityName ? (
                <>
                  <li>• "Show me the top-rated activities in {cityName}"</li>
                  <li>• "Find food tours under $100"</li>
                  <li>• "What are the best morning activities?"</li>
                  <li>• "Recommend cultural experiences"</li>
                </>
              ) : (
                <>
                  <li>• "I'd like a quote for my dream vacation"</li>
                  <li>• "What are the best hidden spots in [destination]?"</li>
                  <li>• "Help me plan a luxury getaway"</li>
                  <li>• "What's the best time to visit [place]?"</li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

function QuickActionsBar({ onAction, hasActivities }: { 
  onAction: (action: string) => void;
  hasActivities: boolean;
}) {
  return (
    <div className="px-4 py-2 border-t border-border bg-background/50">
      <div className="flex gap-2 overflow-x-auto scrollbar-hide pb-2">
        <QuickActionButton 
          icon={Star} 
          label="Top Rated"
          onClick={() => onAction('topRated')}
          disabled={!hasActivities}
        />
        <QuickActionButton 
          icon={Clock} 
          label="Duration"
          onClick={() => onAction('duration')}
          disabled={!hasActivities}
        />
        <QuickActionButton 
          icon={DollarSign} 
          label="Price"
          onClick={() => onAction('price')}
          disabled={!hasActivities}
        />
        <QuickActionButton 
          icon={Tag} 
          label="Categories"
          onClick={() => onAction('categories')}
          disabled={!hasActivities}
        />
      </div>
    </div>
  );
}

function ActivityPanel({ 
  isOpen, 
  onClose, 
  activities, 
  cityName 
}: { 
  isOpen: boolean;
  onClose: () => void;
  activities?: AmadeusActivity[];
  cityName?: string;
}) {
  if (!activities || !cityName) return null;

  return (
    <div className={cn(
      "absolute inset-y-0 right-0 w-full sm:w-[350px] bg-background border-l border-border",
      "transform transition-transform duration-300",
      isOpen ? "translate-x-0" : "translate-x-full"
    )}>
      <div className="flex items-center justify-between p-4 border-b border-border">
        <h3 className="font-medium">Activities in {cityName}</h3>
        <button 
          onClick={onClose}
          className="p-1 hover:bg-gray-800 rounded-lg transition-colors"
        >
          <X className="w-4 h-4" />
        </button>
      </div>
      
      <div className="overflow-y-auto h-[calc(100%-60px)]">
        <div className="p-4 space-y-4">
          {activities.map(activity => (
            <ActivityCard 
              key={activity.id}
              activity={activity}
              compact
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default function ChatInterface({ activities, cityName }: EnhancedChatInterfaceProps) {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { state, dispatch, sendMessage } = useChat();
  const [showActivityPanel, setShowActivityPanel] = useState(false);

  const handleScrollNeeded = (behavior: 'auto' | 'smooth') => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior });
    }
  };

  useEffect(() => {
    if (messagesEndRef.current && state.messages.length > 0) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [state.messages]);

  const handleQuickAction = async (action: string) => {
    let message = '';
    switch (action) {
      case 'topRated':
        message = `What are the top-rated activities in ${cityName}?`;
        break;
      case 'duration':
        message = `Show me activities by duration in ${cityName}`;
        break;
      case 'price':
        message = `What are the price ranges for activities in ${cityName}?`;
        break;
      case 'categories':
        message = `What types of activities are available in ${cityName}?`;
        break;
    }
    if (message) await handleMessageSubmit(message);
  };

  const handleMessageSubmit = async (message: string) => {
    const quotationKeywords = ['quote', 'booking', 'price', 'cost', 'package', 'plan'];
    const isQuotationRequest = quotationKeywords.some(keyword => 
      message.toLowerCase().includes(keyword)
    );

    if (isQuotationRequest) {
      dispatch({
        type: 'START_WORKFLOW',
        workflow: {
          type: 'quoteRequest',
          step: 'initial',
          data: {
            initialQuery: message
          }
        }
      });
    }

    await sendMessage(message);
  };

  if (!state.isOpen) return null;

  return (
    <div className="fixed inset-0 sm:inset-auto sm:bottom-4 sm:right-4 w-full sm:w-[450px] z-50">
      <div className={cn(
        "flex flex-col bg-background/95 backdrop-blur-sm border border-border",
        "rounded-none sm:rounded-xl overflow-hidden shadow-2xl",
        "transition-all duration-300 ease-in-out transform",
        state.isMinimized ? "h-[48px]" : "h-full sm:h-[600px]",
        state.isOpen ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
      )}>
        {/* Loading Overlay */}
        {!state.isInitialized && (
          <div className="absolute inset-0 bg-background/80 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="text-center space-y-3 p-4">
              <Loader2 className="w-6 h-6 animate-spin text-primary mx-auto" />
              <p className="text-sm font-medium text-foreground">
                Setting up your personal travel assistant...
              </p>
            </div>
          </div>
        )}

        {/* Error Message */}
        {state.error && (
          <div className={cn(
            "absolute top-0 left-0 right-0",
            "bg-destructive/10 border-b border-destructive/20",
            "px-4 py-2 text-sm text-destructive",
            "animate-in slide-in-from-top duration-300"
          )}>
            <div className="flex items-center gap-2">
              <span className="text-xs">{state.error}</span>
            </div>
          </div>
        )}

        <ChatHeader 
          cityName={cityName}
          activitiesCount={activities?.length}
        />
        
        {!state.isMinimized && (
          <>
            {/* Activity Context Banner */}
            {activities && cityName && (
              <div className="bg-accent/10 px-4 py-2 border-b border-border">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <MapPin className="w-4 h-4 text-accent" />
                    <span className="text-sm text-accent">
                      {activities.length} activities in {cityName}
                    </span>
                  </div>
                  <button 
                    onClick={() => setShowActivityPanel(true)}
                    className="text-xs text-accent hover:text-accent/80"
                  >
                    View All
                  </button>
                </div>
              </div>
            )}

            <div className="relative flex-1 overflow-hidden">
              {/* Welcome Message */}
              {state.messages.length === 0 && state.isInitialized && (
                <WelcomeMessage 
                  cityName={cityName}
                  activitiesCount={activities?.length}
                />
              )}
              
              <ChatMessages 
                messagesEndRef={messagesEndRef} 
                onScrollNeeded={handleScrollNeeded}
                activities={activities}
                cityName={cityName}
              />
            </div>

            {/* Quick Actions Bar */}
            <QuickActionsBar 
              onAction={handleQuickAction}
              hasActivities={!!activities?.length}
            />

            <ChatInput 
              onSubmit={handleMessageSubmit}
              suggestedPrompts={cityName ? [
                `Show me the top-rated activities in ${cityName}`,
                `What are the best cultural experiences in ${cityName}?`,
                `Find food tours in ${cityName}`,
                `What activities are available in the morning?`
              ] : undefined}
            />
          </>
        )}

        {/* Activity Panel Slide-out */}
        <ActivityPanel 
          isOpen={showActivityPanel}
          onClose={() => setShowActivityPanel(false)}
          activities={activities}
          cityName={cityName}
        />
      </div>
    </div>
  );
} 