import { ChatAnthropic } from '@langchain/anthropic';
import { 
  BaseMessage,
  SystemMessage, 
  HumanMessage, 
  AIMessage,
  MessageContent,
  MessageContentComplex
} from '@langchain/core/messages';
import { 
  ChatPromptTemplate, 
  MessagesPlaceholder 
} from '@langchain/core/prompts';
import { 
  RunnableSequence,
  RunnablePassthrough 
} from '@langchain/core/runnables';
import { QuotationWorkflow } from './workflowPrompts';
import { handleError } from '../utils/error';
import { supabase } from '../supabase';
import type { ChatHistory } from '../../types/chat';
import { QuotationService } from '../../services/QuotationService';
import { AmadeusService } from '../../services/amadeus';

// Initialize AmadeusService
const amadeusService = new AmadeusService(
  import.meta.env.VITE_AMADEUS_CLIENT_ID,
  import.meta.env.VITE_AMADEUS_CLIENT_SECRET,
  import.meta.env.VITE_AMADEUS_PRODUCTION === 'true'
);

// Update system prompt to include activity-related capabilities
export const SYSTEM_PROMPT = `You are Marco, a travel assistant. CRITICAL: Keep responses under 2 sentences.

Rules:
- Maximum 2 sentences per response
- Be direct and friendly
- Ask one question at a time
- No explanations or elaborations
- For activities, mention key details: duration, rating, price

Examples:
"Hi! Where would you like to travel?"
"Perfect! When are you planning to visit?"
"Could you share your email for the quote?"
"Here are some top-rated historical tours in Istanbul, including the Blue Mosque and Hagia Sophia."`;

// Add interface for activity context
interface ActivityContext {
  cityName: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
}

// Add type for message format
interface ChatMessage {
  role: 'user' | 'assistant' | 'system';
  content: string;
}

// Update the interface
interface QuoteRequest {
  id: string;
  name: string | null;
  email: string | null;
  phone: string | null;
  destination: string | null;
  preferences: {
    travelDates?: string;
    travelers?: number;
    accommodationType?: 'budget' | 'mid-range' | 'luxury';
    budget?: string;
    interests?: string[];
    mealPreference?: 'vegetarian' | 'non-vegetarian' | 'halal' | 'other';
  };
  chatHistory: Array<{
    role: 'user' | 'assistant';
    content: string;
    timestamp: number;
  }>;
  status: 'pending' | 'processing' | 'completed';
  created_at: string;
  updated_at: string;
}

export class ChatService {
  private model: ChatAnthropic;
  private prompt: ChatPromptTemplate;
  private chain: RunnableSequence;
  private messageHistory: BaseMessage[] = [];
  private activityContext?: ActivityContext;

  constructor() {
    if (!import.meta.env.VITE_ANTHROPIC_API_KEY) {
      throw new Error('ANTHROPIC_API_KEY is required');
    }

    // Initialize the model
    this.model = new ChatAnthropic({
      anthropicApiKey: import.meta.env.VITE_ANTHROPIC_API_KEY,
      modelName: "claude-3-haiku-20240307",
      temperature: 0.7,
      maxTokens: 100
    });

    // Create the prompt template with proper formatting
    this.prompt = ChatPromptTemplate.fromTemplate(`
      System: ${SYSTEM_PROMPT}
      History: {history}
      Human: {input}
    `);

    // Create the chain with simplified inputs
    this.chain = RunnableSequence.from([
      {
        input: new RunnablePassthrough(),
        history: () => this.formatHistory(),
      },
      this.prompt,
      this.model
    ]);
  }

  // Set activity context for the chat
  setActivityContext(context: ActivityContext) {
    this.activityContext = context;
  }

  // Handle activity-related queries
  private async handleActivityQuery(query: string): Promise<string> {
    if (!this.activityContext) {
      return "I need to know which city you're interested in before I can show you activities.";
    }

    try {
      const { latitude, longitude } = this.activityContext.coordinates;
      const response = await amadeusService.getActivitiesByLocation(latitude, longitude);
      
      // Filter activities based on query keywords
      const activities = response.data;
      const filteredActivities = this.filterActivitiesByQuery(activities, query);
      
      if (filteredActivities.length === 0) {
        return `I couldn't find any activities matching your request in ${this.activityContext.cityName}. Would you like to see all available activities instead?`;
      }

      // Format top 3 activities
      const topActivities = filteredActivities.slice(0, 3);
      const activityList = topActivities.map(activity => {
        const price = activity.price ? `${activity.price.amount} ${activity.price.currencyCode}` : 'Price on request';
        return `${activity.name} (${activity.rating}★, ${price})`;
      }).join(', ');

      return `Here are some top activities in ${this.activityContext.cityName}: ${activityList}`;
    } catch (error) {
      console.error('Error fetching activities:', error);
      return "I'm having trouble accessing the activities right now. Please try again later.";
    }
  }

  // Helper method to filter activities based on user query
  private filterActivitiesByQuery(activities: any[], query: string): any[] {
    const keywords = query.toLowerCase();
    return activities.filter(activity => {
      const text = `${activity.name} ${activity.shortDescription} ${activity.description || ''}`.toLowerCase();
      
      // Check for specific types of activities
      if (keywords.includes('historical') && text.includes('history')) return true;
      if (keywords.includes('food') && (text.includes('food') || text.includes('culinary'))) return true;
      if (keywords.includes('cultural') && text.includes('culture')) return true;
      if (keywords.includes('top') || keywords.includes('best')) {
        return activity.rating && parseFloat(activity.rating) >= 4.0;
      }
      
      return text.includes(keywords);
    });
  }

  async saveMessageHistory(userId: string, email?: string): Promise<void> {
    try {
      console.log('Saving chat history:', {
        userId,
        email,
        messageCount: this.messageHistory.length
      });

      const messages = this.formatHistory();
      
      const { error } = await supabase
        .from('chat_histories')
        .upsert({
          user_id: userId,
          email: email,
          messages: messages, // Now storing as an array of message objects
          updated_at: new Date().toISOString()
        }, {
          onConflict: 'user_id'
        });

      if (error) {
        console.error('Error saving chat history:', error);
        throw error;
      }

      console.log('Chat history saved successfully');
    } catch (error) {
      console.error('Error in saveMessageHistory:', error);
      handleError(error);
    }
  }

  async loadMessageHistory(userId: string): Promise<void> {
    try {
      const { data, error } = await supabase
        .from('chat_histories')
        .select('messages')
        .eq('user_id', userId)
        .single();

      if (error) throw error;
      
      if (data?.messages) {
        this.messageHistory = data.messages.map((msg: ChatMessage) => {
          if (msg.role === 'user') return new HumanMessage({ content: msg.content });
          if (msg.role === 'assistant') return new AIMessage({ content: msg.content });
          return new SystemMessage({ content: msg.content });
        });
      }
    } catch (error) {
      handleError(error);
    }
  }

  async sendMessage(content: string): Promise<string> {
    try {
      // Add user message to history
      const userMessage = new HumanMessage({ content });
      this.messageHistory.push(userMessage);

      // Check if this is an activity-related query
      const isActivityQuery = content.toLowerCase().includes('tour') || 
                            content.toLowerCase().includes('activity') ||
                            content.toLowerCase().includes('visit') ||
                            content.toLowerCase().includes('see') ||
                            content.toLowerCase().includes('explore');

      let response: string;
      if (isActivityQuery && this.activityContext) {
        response = await this.handleActivityQuery(content);
      } else {
        // Get AI response using LangChain
        const aiResponse = await this.chain.invoke({
          input: content
        });
        response = aiResponse?.content || 'I apologize, but I encountered an issue.';
      }

      // Add AI response to history
      const aiMessage = new AIMessage({ content: response });
      this.messageHistory.push(aiMessage);

      return response;
    } catch (error) {
      console.error('Error in sendMessage:', error);
      handleError(error);
      return 'I apologize, but I encountered an issue processing your message.';
    }
  }

  getHistory(): BaseMessage[] {
    return this.messageHistory;
  }

  clearHistory(): void {
    this.messageHistory = [];
  }

  // Helper method to format history
  private formatHistory(): Array<{role: string; content: string; timestamp: number}> {
    return this.messageHistory.map(msg => ({
      role: msg instanceof HumanMessage ? 'user' : 
            msg instanceof AIMessage ? 'assistant' : 'system',
      content: this.getMessageString(msg.content),
      timestamp: Date.now()
    }));
  }

  // Helper method to handle different message content types
  private getMessageString(content: MessageContent): string {
    if (typeof content === 'string') {
      return content.toLowerCase();
    }
    if (Array.isArray(content)) {
      return content
        .map(item => {
          if (typeof item === 'string') return item;
          return 'text' in item ? item.text : '';
        })
        .join(' ')
        .toLowerCase();
    }
    // Type assertion to handle MessageContentComplex
    const complexContent = content as MessageContentComplex;
    if ('text' in complexContent) {
      return complexContent.text.toLowerCase();
    }
    return '';
  }

  // Add this test function
  async testQuotationSave() {
    try {
      console.log('Testing quotation save...');
      const testData = {
        email: 'test@example.com',
        phone: '1234567890',
        destination: 'London',
        travel_date: '2024-06-20',
        travelers: 2,
        status: 'pending'
      };

      console.log('Attempting to save test quotation:', testData);
      const { data, error } = await supabase
        .from('quotation_requests')
        .insert([testData])
        .select();

      if (error) {
        console.error('Error saving test quotation:', error);
        throw error;
      }

      console.log('Test quotation saved successfully:', data);
      return data;
    } catch (error) {
      console.error('Test quotation save failed:', error);
      if (error instanceof Error) {
        console.error('Error details:', {
          message: error.message,
          name: error.name,
          stack: error.stack
        });
      }
      throw error;
    }
  }

  // Add this simple test function
  async testSimpleInsert() {
    try {
      const { data, error } = await supabase
        .from('quotation_requests')
        .insert({
          email: 'test@example.com',
          destination: 'London',
          status: 'pending'
        })
        .select();

      console.log('Insert result:', { data, error });
      return { data, error };
    } catch (error) {
      console.error('Insert failed:', error);
      throw error;
    }
  }

  // Add the handleQuoteRequest method
  private async handleQuoteRequest(messages: BaseMessage[]): Promise<void> {
    try {
      const data = {
        name: null as string | null,
        email: null as string | null,
        phone: null as string | null,
        destination: null as string | null,
        preferences: {
          travelDates: undefined,
          travelers: undefined,
          accommodationType: undefined,
          budget: undefined,
          interests: [],
          mealPreference: undefined
        } as Record<string, any>,
        status: 'pending' as const
      };

      // Process messages
      for (const msg of messages) {
        const content = this.getMessageString(msg.content).toLowerCase();
        console.log('Processing message:', content);
        
        // Extract name
        const namePatterns = [
          /(?:my name is|i am|i'm) ([a-z\s]+?)(?:\s|$)/i,
          /([a-z\s]+) here/i,
          /this is ([a-z\s]+)/i
        ];
        
        for (const pattern of namePatterns) {
          const nameMatch = content.match(pattern);
          if (nameMatch) {
            data.name = nameMatch[1].trim();
            break;
          }
        }

        // Extract email - more flexible now
        const emailMatch = content.match(/([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/i);
        if (emailMatch) {
          data.email = emailMatch[1].toLowerCase();
        }

        // Extract phone
        const phoneMatch = content.match(/(?:phone|call|contact|whatsapp|mobile)[:\s]*([+\d\s-]{8,})/i) ||
          content.match(/(\d{6,})/);
        if (phoneMatch) {
          data.phone = phoneMatch[1].replace(/[\s-]/g, '');
        }
        
        // Extract destination
        const destinations = ['maldives', 'dubai', 'paris', 'london', 'colombo', 'thailand', 'singapore'];
        for (const dest of destinations) {
          if (content.includes(dest)) {
            data.destination = dest.charAt(0).toUpperCase() + dest.slice(1);
            break;
          }
        }
        
        // Extract preferences
        // Travel dates - more flexible
        const datePatterns = [
          /(\d+(?:st|nd|rd|th)?\s*(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)[a-z]*)/i,
          /(?:in|on|during) ((?:early|mid|late)?\s*(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)[a-z]*)/i,
          /(\d{1,2}(?:\/|-)\d{1,2}(?:\/|-)\d{2,4})/
        ];

        for (const pattern of datePatterns) {
          const dateMatch = content.match(pattern);
          if (dateMatch) {
            data.preferences.travelDates = dateMatch[1];
            break;
          }
        }
        
        // Travelers
        const travelersMatch = content.match(/(\d+)\s*(?:people|travelers?|persons?|pax|adults?|guests?)/i);
        if (travelersMatch) {
          data.preferences.travelers = parseInt(travelersMatch[1]);
        }

        // Budget
        const budgetMatch = content.match(/(?:budget|cost|spend|price)[^\d]*(\d[\d,]*)/i);
        if (budgetMatch) {
          data.preferences.budget = budgetMatch[1].replace(/,/g, '');
        }

        // Accommodation type
        if (content.includes('luxury') || content.includes('5 star')) {
          data.preferences.accommodationType = 'luxury';
        } else if (content.includes('budget') || content.includes('cheap')) {
          data.preferences.accommodationType = 'budget';
        } else if (content.includes('mid') || content.includes('moderate')) {
          data.preferences.accommodationType = 'mid-range';
        }

        // Interests
        const interests = ['beach', 'culture', 'food', 'adventure', 'shopping', 'nature'];
        const foundInterests = interests.filter(interest => content.includes(interest));
        if (foundInterests.length > 0) {
          data.preferences.interests = [...new Set([...data.preferences.interests || [], ...foundInterests])];
        }

        // Meal preferences
        if (content.includes('vegetarian')) {
          data.preferences.mealPreference = 'vegetarian';
        } else if (content.includes('halal')) {
          data.preferences.mealPreference = 'halal';
        } else if (content.includes('non-vegetarian')) {
          data.preferences.mealPreference = 'non-vegetarian';
        }
      }

      console.log('Final quote data:', JSON.stringify(data, null, 2));

      // Save if we have any meaningful data
      if (data.email || data.destination) {
        const { error } = await supabase
          .from('quotation_requests')
          .insert([{
            name: data.name,
            email: data.email,
            phone: data.phone,
            destination: data.destination,
            preferences: data.preferences,
            status: data.status
          }]);

        if (error) {
          console.error('Supabase error:', error);
          throw error;
        }
        
        console.log('Quote saved successfully');
      }

    } catch (error) {
      console.error('Error handling quote request:', error);
      throw error;
    }
  }
}

// Create singleton instance
export const chatService = new ChatService(); 

// At the end of the file, after creating the singleton instance
declare global {
  interface Window {
    chatService: ChatService;
  }
}

window.chatService = chatService; 