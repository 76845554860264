import { Minimize2, Maximize2, X, MapPin } from 'lucide-react';
import { useChat } from '../../contexts/ChatContext';
import { cn } from '../../lib/utils';

interface ChatHeaderProps {
  cityName?: string;
  activitiesCount?: number;
}

export default function ChatHeader({ cityName, activitiesCount }: ChatHeaderProps) {
  const { state, dispatch } = useChat();

  const handleMinimizeMaximize = () => {
    dispatch({
      type: state.isMinimized ? 'MAXIMIZE_CHAT' : 'MINIMIZE_CHAT'
    });
  };

  return (
    <div className="flex items-center justify-between px-4 py-3 border-b border-border">
      <div className="flex items-center space-x-3">
        <div className="flex items-center space-x-2">
          <div className="w-2 h-2 rounded-full bg-primary animate-pulse" />
          <div className="flex flex-col leading-none">
            <h3 className="text-sm font-medium">Marco</h3>
            <span className="text-xs text-muted-foreground">by AgenTrav</span>
          </div>
        </div>

        {cityName && !state.isMinimized && (
          <div className="h-4 w-px bg-border mx-2" />
        )}

        {cityName && !state.isMinimized && (
          <div className="flex items-center space-x-1.5 text-xs text-muted-foreground">
            <MapPin className="w-3 h-3" />
            <span>{cityName}</span>
            {activitiesCount !== undefined && (
              <span className="text-primary">({activitiesCount})</span>
            )}
          </div>
        )}

        {state.isTyping && (
          <span className="text-xs text-muted-foreground ml-2">(exploring...)</span>
        )}
      </div>
      
      <div className="flex items-center space-x-1">
        <button
          onClick={handleMinimizeMaximize}
          className="p-1.5 hover:bg-muted rounded-md transition-colors"
          title={state.isMinimized ? "Maximize chat" : "Minimize chat"}
        >
          {state.isMinimized ? (
            <Maximize2 className="w-4 h-4" />
          ) : (
            <Minimize2 className="w-4 h-4" />
          )}
        </button>
        <button
          onClick={() => dispatch({ type: 'CLOSE_CHAT' })}
          className="p-1.5 hover:bg-muted rounded-md transition-colors"
          title="Close chat"
        >
          <X className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
} 