interface IndexNowRequest {
  host: string;
  key: string;
  keyLocation: string;
  urlList: string[];
}

interface IndexNowResponse {
  success: boolean;
  message?: string;
}

export const generateApiKey = (): string => {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const length = 32;
  return Array.from({ length }, () => chars.charAt(Math.floor(Math.random() * chars.length))).join('');
};

export const validateUrls = (urls: string[], host: string): { valid: boolean; message?: string } => {
  try {
    const normalizedHost = host.toLowerCase().trim().replace(/^www\./, '');
    
    for (const url of urls) {
      const parsedUrl = new URL(url);
      const urlHost = parsedUrl.hostname.toLowerCase().replace(/^www\./, '');
      
      if (urlHost !== normalizedHost) {
        return {
          valid: false,
          message: `URL ${url} does not match the host domain ${host}`
        };
      }
    }
    
    return { valid: true };
  } catch (error) {
    return {
      valid: false,
      message: error instanceof Error ? error.message : 'Invalid URL format'
    };
  }
};

export const getKeyFileInstructions = (key: string, host: string): string => {
  return `To verify ownership of your domain, create a text file with the following specifications:

1. File name: ${key}.txt
2. File content: ${key}
3. File location: https://${host}/${key}.txt or https://${host}/.well-known/${key}.txt

The file should be accessible via HTTP/HTTPS and return:
- Content-Type: text/plain
- HTTP 200 status code
- Exact content matching the key`;
};

export const submitUrlsToIndexNow = async (
  urls: string[],
  apiKey: string,
  host: string,
  keyLocation: string
): Promise<IndexNowResponse> => {
  try {
    // Validate URLs match the host domain
    const validation = validateUrls(urls, host);
    if (!validation.valid) {
      return {
        success: false,
        message: validation.message
      };
    }

    const payload: IndexNowRequest = {
      host,
      key: apiKey,
      keyLocation,
      urlList: urls,
    };

    const response = await fetch('https://api.indexnow.org/indexnow', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.text();
    
    if (!response.ok) {
      // Handle specific error cases
      switch (response.status) {
        case 400:
          return {
            success: false,
            message: 'Invalid request. Please check your API key and key location.'
          };
        case 403:
          return {
            success: false,
            message: 'API key verification failed. Make sure your key file is properly hosted.'
          };
        case 422:
          return {
            success: false,
            message: 'Invalid URLs provided. URLs must be accessible and belong to your domain.'
          };
        case 429:
          return {
            success: false,
            message: 'Too many requests. Please try again later.'
          };
        default:
          throw new Error(
            `HTTP error! status: ${response.status} - ${responseData || response.statusText}`
          );
      }
    }

    // IndexNow returns an empty response on success
    if (!responseData) {
      return { 
        success: true,
        message: 'URLs submitted successfully'
      };
    }

    try {
      // Try to parse response as JSON if it exists
      const jsonResponse = JSON.parse(responseData);
      return {
        success: response.ok,
        message: jsonResponse.message || 'URLs submitted successfully'
      };
    } catch {
      // If response is not JSON, return the text
      return {
        success: response.ok,
        message: responseData
      };
    }
  } catch (error) {
    console.error('Error submitting URLs to IndexNow:', error);
    return {
      success: false,
      message: error instanceof Error ? error.message : 'Failed to submit URLs'
    };
  }
}; 