import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRedirectPath } from '../utils/redirects';

export default function RedirectHandler() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const redirectPath = getRedirectPath(location.pathname);
    if (redirectPath && redirectPath !== location.pathname) {
      // Use replace to avoid adding to browser history
      navigate(redirectPath, { 
        replace: true,
        state: { 
          from: location.pathname,
          isRedirect: true 
        }
      });
    }
  }, [location.pathname, navigate]);

  return null;
} 