import axios, { AxiosError } from 'axios';
import type { RequestInit } from 'node-fetch';
import { supabase } from '../lib/supabase';

interface AmadeusAuthResponse {
  type: string;
  username: string;
  application_name: string;
  client_id: string;
  token_type: string;
  access_token: string;
  expires_in: number;
  state: string;
  scope: string;
}

export interface AmadeusActivity {
  id: string;
  type: string;
  self: {
    href: string;
    methods: string[];
  };
  name: string;
  shortDescription: string;
  description?: string;
  geoCode: {
    latitude: string;
    longitude: string;
  };
  rating?: string;
  pictures?: string[];
  bookingLink?: string;
  price?: {
    currencyCode: string;
    amount: string;
  };
  minimumDuration?: string;
}

interface AmadeusResponse {
  meta: {
    count: string;
    links: {
      self: string;
    };
  };
  data: AmadeusActivity[];
}

interface AmadeusError {
  errors: Array<{
    status: number;
    code: number;
    title: string;
    detail?: string;
    source?: {
      parameter?: string;
      example?: string;
    };
  }>;
}

export interface PaginationParams {
  page?: number;
  limit?: number;
}

export interface FilterParams {
  priceRange?: {
    min?: number;
    max?: number;
  };
  rating?: number;
  duration?: string;
  categories?: string[];
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
}

// Add activity categories enum
export enum ActivityCategory {
  SIGHTSEEING = 'Sightseeing',
  CULTURAL = 'Cultural',
  ADVENTURE = 'Adventure',
  FOOD_AND_DRINK = 'Food & Drink',
  SHOPPING = 'Shopping',
  NATURE = 'Nature',
  ENTERTAINMENT = 'Entertainment',
  WELLNESS = 'Wellness'
}

interface GetActivitiesOptions {
  radius?: number;
  pagination?: PaginationParams;
  filters?: FilterParams;
}

export interface ActivitySearchParams {
  pagination?: {
    page: number;
    limit: number;
  };
  filters?: FilterParams;
  radius?: number; // 0-20km
}

export interface SquareSearchParams {
  north: number;
  south: number;
  east: number;
  west: number;
  pagination?: {
    page: number;
    limit: number;
  };
  filters?: FilterParams;
}

export interface AmadeusHotel {
  hotelId: string;
  chainCode?: string;
  name: string;
  rating?: number;
  description?: {
    text: string;
    lang: string;
  };
  amenities?: string[];
  media?: {
    uri: string;
    category: string;
  }[];
  cityCode: string;
  address: {
    countryCode: string;
    stateCode?: string;
    cityName: string;
    postalCode?: string;
    lines: string[];
  };
  geoCode: {
    latitude: number;
    longitude: number;
  };
  contact?: {
    phone?: string;
    email?: string;
    website?: string;
  };
}

export interface BaseSearchParams {
  filters?: {
    priceRange?: {
      min?: number;
      max?: number;
    };
    ratings?: number[];
    amenities?: string[];
  };
  radius?: number;
}

export interface GeocodeSearchParams extends BaseSearchParams {
  pagination?: {
    page: number;
    limit: number;
  };
}

export interface HotelSearchParams extends GeocodeSearchParams {
  checkInDate: string;
  checkOutDate: string;
  adults?: number;
  roomQuantity?: number;
  currency?: string;
  latitude?: number;
  longitude?: number;
  hotelIds?: string | string[];
}

export interface HotelOfferSearch {
  type: string;
  hotel: AmadeusHotel;
  available: boolean;
  offers: Array<{
    id: string;
    checkInDate: string;
    checkOutDate: string;
    rateCode: string;
    rateFamilyEstimated: {
      code: string;
      type: string;
    };
    room: {
      type: string;
      typeEstimated: {
        category: string;
        beds: number;
        bedType: string;
      };
      description: {
        text: string;
        lang: string;
      };
    };
    guests: {
      adults: number;
      childAges?: number[];
    };
    price: {
      currency: string;
      base: string;
      total: string;
      variations?: {
        average?: {
          base: string;
        };
        changes?: Array<{
          startDate: string;
          endDate: string;
          base: string;
        }>;
      };
    };
    policies?: {
      guarantee?: {
        acceptedPayments: {
          creditCards: string[];
          methods: string[];
        };
      };
      paymentType: string;
      cancellation?: {
        description?: {
          text: string;
          lang: string;
        };
        type?: string;
        deadline?: string;
        amount?: string;
        percentage?: string;
      };
    };
    self: string;
  }>;
  self?: string;
}

export interface HotelOfferParams {
  checkInDate: string;
  checkOutDate: string;
  adults?: number;
  roomQuantity?: number;
  priceRange?: {
    min?: number;
    max?: number;
  };
  currency?: string;
  boardType?: 'ROOM_ONLY' | 'BREAKFAST' | 'HALF_BOARD' | 'FULL_BOARD' | 'ALL_INCLUSIVE';
  paymentPolicy?: 'GUARANTEE' | 'DEPOSIT' | 'NONE';
  includeClosed?: boolean;
  bestRateOnly?: boolean;
}

interface HotelOfferResponse {
  data: Array<{
    type: string;
    hotel: {
      hotelId: string;
      name: string;
      rating?: number;
      description?: {
        text: string;
        lang: string;
      };
      amenities?: string[];
      media?: {
        uri: string;
        category: string;
      }[];
      cityCode: string;
      address: {
        countryCode: string;
        stateCode?: string;
        cityName: string;
        postalCode?: string;
        lines: string[];
      };
      geoCode: {
        latitude: number;
        longitude: number;
      };
      contact?: {
        phone?: string;
        email?: string;
        website?: string;
      };
    };
    available: boolean;
    offers?: Array<{
      id: string;
      checkInDate: string;
      checkOutDate: string;
      price: {
        currency: string;
        total: string;
        base: string;
      };
    }>;
  }>;
  meta: {
    count: string;
  };
}

export interface PricingResponse {
  data: {
    type: string;
    id: string;
    checkInDate: string;
    checkOutDate: string;
    roomQuantity: number;
    guests: {
      adults: number;
    };
    price: {
      currency: string;
      base: string;
      total: string;
      taxes: Array<{
        code: string;
        amount: string;
        currency: string;
        included: boolean;
      }>;
    };
    policies: {
      paymentType: string;
      cancellation?: {
        description: {
          text: string;
          lang: string;
        };
        type: string;
        amount?: string;
        deadline?: string;
      };
    };
  };
}

export class AmadeusService {
  private baseUrl: string;
  private clientId: string;
  private clientSecret: string;
  private accessToken: string | null = null;
  private tokenExpiration: number = 0;
  private refreshTimeout: NodeJS.Timeout | null = null;
  private authLock: boolean = false;

  constructor(clientId: string, clientSecret: string, isProduction: boolean = false) {
    this.clientId = clientId;
    this.clientSecret = clientSecret;
    // Use direct API URLs
    this.baseUrl = isProduction
      ? 'https://api.amadeus.com'
      : 'https://test.api.amadeus.com';
  }

  private getApiUrl(endpoint: string): string {
    // Use v3 for hotel offers, v1 for other endpoints
    if (endpoint.startsWith('/shopping/hotel-offers')) {
      return `${this.baseUrl}/v3${endpoint}`;
    }
    return `${this.baseUrl}/v1${endpoint}`;
  }

  async getActivitiesByLocation(
    latitude: number,
    longitude: number,
    params?: ActivitySearchParams
  ): Promise<{ data: AmadeusActivity[]; meta: { count: number } }> {
    const queryParams = new URLSearchParams({
      latitude: latitude.toString(),
      longitude: longitude.toString(),
      ...(params?.radius && { radius: params.radius.toString() }),
    });

    const response = await this.makeRequest<AmadeusResponse>('get', `/shopping/activities?${queryParams}`);
    return this.processActivitiesResponse(response);
  }

  async getActivitiesBySquare(params: SquareSearchParams): Promise<{ data: AmadeusActivity[]; meta: { count: number } }> {
    const queryParams = new URLSearchParams({
      north: params.north.toString(),
      south: params.south.toString(),
      east: params.east.toString(),
      west: params.west.toString(),
    });

    const response = await this.makeRequest<AmadeusResponse>('get', `/shopping/activities/by-square?${queryParams}`);
    return this.processActivitiesResponse(response);
  }

  async getActivityById(activityId: string): Promise<AmadeusActivity> {
    const response = await this.makeRequest<{ data: AmadeusActivity }>('get', `/shopping/activities/${activityId}`);
    return response.data;
  }

  async getHotelsByCity(
    cityCode: string,
    params?: HotelSearchParams
  ): Promise<{ data: AmadeusHotel[]; meta: { count: number } }> {
    const queryParams = new URLSearchParams({
      cityCode,
      ...(params?.radius && { radius: params.radius.toString() }),
      ...(params?.pagination?.page && { page: params.pagination.page.toString() }),
      ...(params?.pagination?.limit && { limit: params.pagination.limit.toString() }),
    });

    const response = await this.makeRequest<{ data: AmadeusHotel[]; meta: { count: string } }>(
      'get',
      `/reference-data/locations/hotels/by-city?${queryParams}`
    );

    return {
      data: response.data,
      meta: {
        count: parseInt(response.meta.count, 10),
      },
    };
  }

  async getHotelsByGeocode(
    latitude: number,
    longitude: number,
    params: GeocodeSearchParams = {}
  ): Promise<{ data: AmadeusHotel[]; meta: { count: number } }> {
    try {
      // Format coordinates to match API requirements
      const formattedLat = Number(latitude).toString();
      const formattedLon = Number(longitude).toString();
      
      // Base parameters - required parameters first
      const queryParams = new URLSearchParams();
      queryParams.append('latitude', formattedLat);
      queryParams.append('longitude', formattedLon);
      
      // Optional parameters
      if (params.radius) {
        queryParams.append('radius', Math.min(Math.max(Math.round(params.radius), 0), 20).toString());
      }
      
      // Add radiusUnit if specified (defaults to KM)
      queryParams.append('radiusUnit', 'KM');

      // Add amenities if specified
      if (params.filters?.amenities?.length) {
        queryParams.append('amenities', params.filters.amenities.join(','));
      }

      // Add ratings if specified (must be string array of 1-5)
      if (params.filters?.ratings?.length) {
        const validRatings = params.filters.ratings
          .map(r => Math.round(r))
          .filter(r => r >= 1 && r <= 5)
          .map(r => r.toString());
        if (validRatings.length) {
          queryParams.append('ratings', validRatings.join(','));
        }
      }

      // Add hotelSource (defaults to ALL)
      queryParams.append('hotelSource', 'ALL');

      const response = await this.makeRequest<{ data: AmadeusHotel[]; meta: { count: string } }>(
        'GET',
        `/reference-data/locations/hotels/by-geocode?${queryParams.toString()}`
      );

      return {
        data: response.data,
        meta: {
          count: parseInt(response.meta.count || '0', 10)
        }
      };
    } catch (error) {
      console.error('Failed to fetch hotels:', error);
      throw error;
    }
  }

  async getHotelOffers(
    hotelIds: string[],
    params: HotelOfferParams
  ): Promise<{ data: HotelOfferSearch[] }> {
    const queryParams = new URLSearchParams({
      hotelIds: hotelIds.join(','),
      checkInDate: params.checkInDate,
      checkOutDate: params.checkOutDate,
      ...(params.adults && { adults: params.adults.toString() }),
      ...(params.roomQuantity && { roomQuantity: params.roomQuantity.toString() }),
      ...(params.priceRange?.min && { priceMin: params.priceRange.min.toString() }),
      ...(params.priceRange?.max && { priceMax: params.priceRange.max.toString() }),
      ...(params.currency && { currency: params.currency }),
      ...(params.boardType && { boardType: params.boardType }),
      ...(params.paymentPolicy && { paymentPolicy: params.paymentPolicy }),
      ...(params.includeClosed !== undefined && { includeClosed: params.includeClosed.toString() }),
      ...(params.bestRateOnly !== undefined && { bestRateOnly: params.bestRateOnly.toString() }),
    });

    const response = await this.makeRequest<{ data: HotelOfferSearch[] }>(
      'get',
      `/shopping/hotel-offers?${queryParams}`
    );

    return response;
  }

  async getHotelOfferById(offerId: string): Promise<{ data: HotelOfferSearch }> {
    const response = await this.makeRequest<{ data: HotelOfferSearch }>(
      'get',
      `/shopping/hotel-offers/${offerId}`
    );

    return response;
  }

  async getMultiHotelOffers(params: HotelSearchParams): Promise<{ data: HotelOfferSearch[] }> {
    try {
      // First, get hotel IDs using geocode API if latitude/longitude are provided
      let hotelIds: string[] = [];
      if (params.latitude && params.longitude) {
        // Validate coordinates
        if (params.latitude < -90 || params.latitude > 90 || params.longitude < -180 || params.longitude > 180) {
          throw new Error('Invalid coordinates');
        }

        const geoResponse = await this.getHotelsByGeocode(
          params.latitude,
          params.longitude,
          {
            radius: params.radius || 20,
            filters: params.filters
          } as GeocodeSearchParams
        );

        hotelIds = geoResponse.data.map(hotel => hotel.hotelId);
        if (hotelIds.length === 0) {
          throw new Error('No hotels found in the specified location');
        }
      } else if (params.hotelIds) {
        hotelIds = Array.isArray(params.hotelIds) ? params.hotelIds : [params.hotelIds];
      } else {
        throw new Error('Either hotelIds or latitude/longitude are required');
      }

      // Build query parameters for hotel offers
      const queryParams = new URLSearchParams();

      // Required parameters validation
      if (!params.checkInDate || !params.checkOutDate) {
        throw new Error('checkInDate and checkOutDate are required');
      }

      // Validate dates
      const checkIn = new Date(params.checkInDate);
      const checkOut = new Date(params.checkOutDate);
      if (checkIn >= checkOut) {
        throw new Error('checkOutDate must be after checkInDate');
      }

      // Validate date format
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      if (!dateRegex.test(params.checkInDate) || !dateRegex.test(params.checkOutDate)) {
        throw new Error('Dates must be in YYYY-MM-DD format');
      }

      // Core parameters
      queryParams.append('hotelIds', hotelIds.join(','));
      queryParams.append('checkInDate', params.checkInDate);
      queryParams.append('checkOutDate', params.checkOutDate);

      // Guest parameters (required)
      const adults = Math.min(Math.max(params.adults || 2, 1), 9);
      const roomQuantity = Math.min(Math.max(params.roomQuantity || 1, 1), 9);
      queryParams.append('adults', adults.toString());
      queryParams.append('roomQuantity', roomQuantity.toString());

      // Optional parameters with validation
      if (params.currency) {
        const validCurrencies = ['USD', 'EUR', 'GBP'];
        const currency = params.currency.toUpperCase();
        if (!validCurrencies.includes(currency)) {
          throw new Error('Invalid currency. Supported currencies: USD, EUR, GBP');
        }
        queryParams.append('currency', currency);
      }

      // Price range filter - only add if min and max are defined and valid
      if (params.filters?.priceRange) {
        const { min, max } = params.filters.priceRange;
        if (min !== undefined && max !== undefined) {
          if (min < 0 || max < 0 || min > max) {
            throw new Error('Invalid price range');
          }
          queryParams.append('priceRange', `${min}-${max}`);
        }
      }

      // Required parameters for v3 API
      queryParams.append('bestRateOnly', 'true');
      queryParams.append('paymentPolicy', 'NONE');
      queryParams.append('view', 'LIGHT');

      console.log('Making hotel offers request with params:', queryParams.toString());

      const response = await this.makeRequest<{ data: HotelOfferSearch[] }>(
        'GET',
        `/shopping/hotel-offers?${queryParams.toString()}`
      );

      return response;
    } catch (error) {
      console.error('Failed to fetch hotel offers:', error);
      throw error;
    }
  }

  async getOfferPricing(offerId: string, lang?: string): Promise<PricingResponse> {
    try {
      const queryParams = new URLSearchParams();
      if (lang) queryParams.append('lang', lang);

      const response = await this.makeRequest<PricingResponse>(
        'GET',
        `/shopping/hotel-offers/${offerId}${queryParams.toString() ? `?${queryParams.toString()}` : ''}`
      );

      return response;
    } catch (error) {
      console.error('Failed to fetch offer pricing:', error);
      throw error;
    }
  }

  private async makeRequest<T>(method: string, endpoint: string, options: { headers?: Record<string, string>; body?: any } = {}): Promise<T> {
    try {
      const token = await this.getAccessToken();
      
      const config = {
        method,
        url: this.getApiUrl(endpoint),
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          ...options.headers
        },
        data: options.body,
        timeout: 30000, // 30 second timeout
        withCredentials: false // Disable sending credentials for CORS
      };

      const response = await axios(config);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Log the full error details for debugging
        console.error('API request failed:', {
          status: error.response?.status,
          data: error.response?.data,
          message: error.message,
          url: this.getApiUrl(endpoint),
          headers: error.response?.headers
        });
        
        if (error.response?.status === 401) {
          // Token might be expired, clear it and retry once
          this.accessToken = null;
          this.tokenExpiration = 0;
          return this.makeRequest(method, endpoint, options);
        }

        // Handle specific error cases with more detailed messages
        if (error.response?.data?.errors) {
          const apiError = error.response.data.errors[0];
          throw new Error(`API Error: ${apiError.title} - ${apiError.detail || apiError.code}`);
        }

        switch (error.response?.status) {
          case 400:
            throw new Error(`Invalid request parameters: ${error.response?.data?.message || 'Please check your input'}`);
          case 403:
            throw new Error('Access forbidden. Please check your API credentials.');
          case 404:
            throw new Error('Resource not found.');
          case 429:
            throw new Error('Rate limit exceeded. Please try again later.');
          case 500:
            throw new Error('Server error. Please try again later.');
          default:
            throw new Error(error.message || 'Unknown API error');
        }
      }
      throw error;
    }
  }

  private processActivitiesResponse(response: AmadeusResponse): { data: AmadeusActivity[]; meta: { count: number } } {
    return {
      data: response.data.map((activity: any) => ({
        ...activity,
        rating: activity.rating ? parseFloat(activity.rating) : undefined,
        price: activity.price ? {
          ...activity.price,
          amount: activity.price.amount,
        } : undefined,
      })),
      meta: {
        count: parseInt(response.meta.count, 10),
      },
    };
  }

  private filterActivities(activities: AmadeusActivity[], filters: FilterParams): AmadeusActivity[] {
    return activities.filter(activity => {
      // Price range filter
      if (filters.priceRange) {
        const price = activity.price ? parseFloat(activity.price.amount) : 0;
        if (filters.priceRange.min !== undefined && price < filters.priceRange.min) return false;
        if (filters.priceRange.max !== undefined && price > filters.priceRange.max) return false;
      }

      // Rating filter
      if (filters.rating !== undefined && activity.rating) {
        if (parseFloat(activity.rating) < filters.rating) return false;
      }

      // Duration filter
      if (filters.duration && activity.minimumDuration) {
        // Convert duration to hours for comparison
        const durationHours = this.parseDuration(activity.minimumDuration);
        const filterHours = this.parseDuration(filters.duration);
        
        if (durationHours === null || filterHours === null) return true;
        return this.isDurationMatch(durationHours, filters.duration);
      }

      // Categories filter
      if (filters.categories && filters.categories.length > 0) {
        // Check if activity description or name contains any of the category keywords
        const activityText = `${activity.name} ${activity.shortDescription} ${activity.description || ''}`.toLowerCase();
        return filters.categories.some(category => {
          const keywords = this.getCategoryKeywords(category);
          return keywords.some(keyword => activityText.includes(keyword.toLowerCase()));
        });
      }

      return true;
    });
  }

  private parseDuration(duration: string): number | null {
    const hourMatch = duration.match(/(\d+).*?hour/i);
    if (hourMatch) {
      return parseInt(hourMatch[1]);
    }
    return null;
  }

  private isDurationMatch(activityHours: number, filterDuration: string): boolean {
    switch (filterDuration) {
      case '1-2 hours':
        return activityHours >= 1 && activityHours <= 2;
      case '2-4 hours':
        return activityHours > 2 && activityHours <= 4;
      case '4-8 hours':
        return activityHours > 4 && activityHours <= 8;
      case '8+ hours':
        return activityHours > 8;
      default:
        return true;
    }
  }

  private getCategoryKeywords(category: string): string[] {
    const categoryKeywords: Record<string, string[]> = {
      [ActivityCategory.SIGHTSEEING]: ['sightseeing', 'tour', 'landmark', 'monument', 'attraction'],
      [ActivityCategory.CULTURAL]: ['museum', 'art', 'history', 'culture', 'heritage', 'traditional'],
      [ActivityCategory.ADVENTURE]: ['adventure', 'hiking', 'climbing', 'rafting', 'kayaking', 'extreme'],
      [ActivityCategory.FOOD_AND_DRINK]: ['food', 'cuisine', 'cooking', 'tasting', 'culinary', 'wine', 'restaurant'],
      [ActivityCategory.SHOPPING]: ['shopping', 'market', 'mall', 'boutique', 'store'],
      [ActivityCategory.NATURE]: ['nature', 'park', 'garden', 'wildlife', 'forest', 'beach'],
      [ActivityCategory.ENTERTAINMENT]: ['show', 'concert', 'theater', 'performance', 'nightlife'],
      [ActivityCategory.WELLNESS]: ['spa', 'massage', 'yoga', 'wellness', 'relaxation']
    };

    return categoryKeywords[category] || [];
  }

  private async getAccessToken(): Promise<string> {
    try {
      if (this.accessToken && Date.now() < this.tokenExpiration) {
        return this.accessToken;
      }

      // Prevent concurrent token requests
      if (this.authLock) {
        await new Promise(resolve => setTimeout(resolve, 100));
        return this.getAccessToken();
      }

      this.authLock = true;

      try {
        const authUrl = `${this.baseUrl.replace('/v1', '')}/v1/security/oauth2/token`;
        
        const response = await axios.post<AmadeusAuthResponse>(
          authUrl,
          new URLSearchParams({
            grant_type: 'client_credentials',
            client_id: this.clientId,
            client_secret: this.clientSecret,
          }),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Accept': 'application/json'
            }
          }
        );

        this.accessToken = response.data.access_token;
        this.tokenExpiration = Date.now() + (response.data.expires_in * 1000);

        // Set up token refresh
        if (this.refreshTimeout) {
          clearTimeout(this.refreshTimeout);
        }

        // Refresh token 5 minutes before expiration
        const refreshTime = (response.data.expires_in - 300) * 1000;
        this.refreshTimeout = setTimeout(() => {
          this.accessToken = null;
          this.tokenExpiration = 0;
        }, refreshTime);

        return this.accessToken;
      } finally {
        this.authLock = false;
      }
    } catch (error) {
      console.error('Failed to get access token:', error);
      throw new Error('Failed to authenticate with Amadeus API');
    }
  }
}