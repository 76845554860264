import { KeyboardEvent, useState, useRef, useEffect } from 'react';
import { useChat } from '../../contexts/ChatContext';
import { Send, Loader2, ChevronUp, ChevronDown } from 'lucide-react';
import { cn } from '../../lib/utils';

interface ChatInputProps {
  onSubmit: (message: string) => Promise<void>;
  suggestedPrompts?: string[];
}

export default function ChatInput({ onSubmit, suggestedPrompts }: ChatInputProps) {
  const [input, setInput] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);
  const { state } = useChat();

  // Auto-resize textarea
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [input]);

  // Close suggestions on click outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target as Node)) {
        setShowSuggestions(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSubmit = async () => {
    if (!input.trim() || state.isTyping || !state.isInitialized || isSubmitting) return;
    
    try {
      setIsSubmitting(true);
      const message = input.trim();
      setInput('');
      setShowSuggestions(false);
      
      // Reset textarea height
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
      }
      
      await onSubmit(message);
    } catch (error) {
      console.error('Failed to send message:', error);
      // Error will be handled by ChatContext
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    setInput(suggestion);
    setShowSuggestions(false);
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  const isDisabled = !state.isInitialized || state.isTyping || isSubmitting;
  const placeholder = !state.isInitialized
    ? "Initializing chat..."
    : state.isTyping
    ? "Assistant is typing..."
    : "Type a message...";

  return (
    <div className="relative">
      {/* Suggestions Panel */}
      {showSuggestions && suggestedPrompts && suggestedPrompts.length > 0 && (
        <div 
          ref={suggestionsRef}
          className="absolute bottom-full left-0 right-0 bg-gray-800/95 backdrop-blur-sm border border-gray-700 rounded-t-lg overflow-hidden"
        >
          <div className="p-2 space-y-1">
            {suggestedPrompts.map((prompt, index) => (
              <button
                key={index}
                onClick={() => handleSuggestionClick(prompt)}
                className="w-full text-left px-3 py-2 text-sm text-gray-300 hover:bg-gray-700/50 rounded-lg transition-colors"
              >
                {prompt}
              </button>
            ))}
          </div>
        </div>
      )}

      <div className="p-4 border-t border-gray-800 bg-gray-900/50">
        <div className="relative flex items-end gap-2">
          <div className="relative flex-1">
            <textarea
              ref={textareaRef}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder={placeholder}
              className={cn(
                "w-full bg-gray-800/50 text-white rounded-xl px-4 py-3",
                "resize-none focus:outline-none focus:ring-2 focus:ring-accent",
                "placeholder:text-gray-400",
                "disabled:opacity-50 disabled:cursor-not-allowed",
                "min-h-[44px] max-h-[200px] overflow-y-auto",
                "scrollbar-thin scrollbar-thumb-gray-800 scrollbar-track-transparent"
              )}
              style={{
                lineHeight: '1.5',
              }}
              rows={1}
              disabled={isDisabled}
            />
            
            {/* Character count */}
            {input.length > 0 && (
              <div className="absolute right-2 bottom-2 text-xs text-gray-400">
                {input.length}/2000
              </div>
            )}

            {/* Suggestions toggle */}
            {suggestedPrompts && suggestedPrompts.length > 0 && (
              <button
                onClick={() => setShowSuggestions(!showSuggestions)}
                className="absolute left-2 top-2 p-1 hover:bg-gray-700/50 rounded-md transition-colors"
              >
                {showSuggestions ? (
                  <ChevronDown className="w-4 h-4 text-gray-400" />
                ) : (
                  <ChevronUp className="w-4 h-4 text-gray-400" />
                )}
              </button>
            )}
          </div>

          <button
            onClick={handleSubmit}
            disabled={!input.trim() || isDisabled}
            className={cn(
              "flex items-center justify-center",
              "w-11 h-11 rounded-xl transition-all duration-200",
              "disabled:opacity-50 disabled:cursor-not-allowed",
              "bg-accent text-black hover:bg-accent/90 active:scale-95",
              "focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2 focus:ring-offset-black"
            )}
          >
            {state.isTyping || isSubmitting ? (
              <Loader2 className="w-5 h-5 animate-spin" />
            ) : (
              <Send className="w-5 h-5" />
            )}
          </button>
        </div>

        {/* Help text */}
        <div className="mt-2 px-2 flex justify-between items-center">
          <p className="text-xs text-gray-500">
            Press <kbd className="px-1 py-0.5 bg-gray-800 rounded text-xs">Enter</kbd> to send,{' '}
            <kbd className="px-1 py-0.5 bg-gray-800 rounded text-xs">Shift + Enter</kbd> for new line
          </p>
          {state.currentWorkflow && (
            <span className="text-xs text-accent">
              #{state.currentWorkflow.type}
            </span>
          )}
        </div>
      </div>
    </div>
  );
} 