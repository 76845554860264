import type { WorkflowState } from '../../types/chat';

export class WorkflowManager {
  private currentWorkflow?: WorkflowState;
  private contextMemory: Record<string, any> = {};

  setWorkflowState(workflow: WorkflowState) {
    this.currentWorkflow = workflow;
    this.contextMemory['lastState'] = workflow;
  }

  getWorkflowState(): WorkflowState | undefined {
    return this.currentWorkflow;
  }

  setContextMemory(key: string, value: any) {
    this.contextMemory[key] = value;
  }

  getContextMemory(key: string): any {
    return this.contextMemory[key];
  }

  clearContext() {
    this.currentWorkflow = undefined;
    this.contextMemory = {};
  }
} 