import { config } from 'dotenv';

// Load environment variables in Node.js context
if (typeof process !== 'undefined' && process.env && typeof process.env === 'object' && !import.meta.env) {
  try {
    const result = config();
    if (result.error) {
      console.warn('Error loading .env file:', result.error);
    } else {
      console.log('Successfully loaded .env file');
    }
  } catch (error) {
    console.warn('Error during env setup:', error);
  }
}

// Helper to get env variable from either Vite or Node environment
export const getEnvVar = (key: string, defaultValue?: string): string => {
  // Debug log
  console.log('Getting environment variable:', {
    key,
    hasImportMeta: typeof import.meta !== 'undefined',
    hasImportMetaEnv: typeof import.meta !== 'undefined' && import.meta.env !== undefined,
    hasProcess: typeof process !== 'undefined',
    hasProcessEnv: typeof process !== 'undefined' && process.env !== undefined,
    processEnvType: typeof process !== 'undefined' && process.env ? typeof process.env : 'undefined'
  });

  // Try Vite environment first (browser)
  if (typeof import.meta !== 'undefined' && import.meta.env && import.meta.env[key]) {
    console.log(`Found ${key} in import.meta.env`);
    return import.meta.env[key];
  }
  
  // Try Node.js environment
  if (typeof process !== 'undefined' && process.env && typeof process.env === 'object') {
    if (process.env[key]) {
      console.log(`Found ${key} in process.env`);
      return process.env[key] as string;
    }
  }
  
  // Use default value if provided
  if (defaultValue !== undefined) {
    console.log(`Using default value for ${key}: ${defaultValue}`);
    return defaultValue;
  }
  
  // If no value found, throw error
  console.error(`Environment variable ${key} not found in any environment`);
  throw new Error(`Environment variable ${key} is not defined`);
};

// Environment variables
export const ENV = {
  SUPABASE_URL: getEnvVar('VITE_SUPABASE_URL'),
  SUPABASE_ANON_KEY: getEnvVar('VITE_SUPABASE_ANON_KEY'),
  SUPABASE_SERVICE_ROLE_KEY: getEnvVar('VITE_SUPABASE_SERVICE_ROLE_KEY', ''),
  ADMIN_EMAIL: getEnvVar('VITE_ADMIN_EMAIL', 'admin@agentrav.com'),
  MAPBOX_TOKEN: getEnvVar('VITE_MAPBOX_TOKEN'),
  NODE_ENV: getEnvVar('NODE_ENV', 'development'),
  GA_MEASUREMENT_ID: getEnvVar('VITE_GA_MEASUREMENT_ID'),
  IS_PRODUCTION: getEnvVar('NODE_ENV', 'development') === 'production'
};

// Validate required environment variables
export const validateEnv = () => {
  console.log('Validating environment variables...');
  const required = [
    'VITE_SUPABASE_URL',
    'VITE_SUPABASE_ANON_KEY'
  ];

  try {
    required.forEach(key => {
      const value = getEnvVar(key);
      if (!value) {
        console.error(`Missing required environment variable: ${key}`);
        throw new Error(`Missing required environment variable: ${key}`);
      }
      console.log(`Validated ${key}`);
    });
    console.log('Environment validation successful');
    return true;
  } catch (error) {
    console.warn('Environment validation failed:', error);
    return false;
  }
}; 