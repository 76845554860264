import { createContext, useContext, useReducer, ReactNode, useEffect, useRef, useCallback, useMemo, useState } from 'react';
import { Message, ChatState, ChatAction, UserPreferences, WorkflowState } from '../types/chat';
import { ChatService } from '../lib/chat/chatService';
import { WorkflowManager } from '../lib/chat/workflowManager';

interface ChatContextType {
  state: ChatState;
  dispatch: React.Dispatch<ChatAction>;
  sendMessage: (content: string, metadata?: Message['metadata']) => Promise<void>;
  setActivityContext: (cityName: string, latitude: number, longitude: number) => void;
  toggleChat: () => void;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

const initialState: ChatState = {
  isOpen: false,
  isMinimized: false,
  isInitialized: false,
  isTyping: false,
  messages: [],
  error: null,
  currentWorkflow: undefined,
  preferences: {},
};

function chatReducer(state: ChatState, action: ChatAction): ChatState {
  switch (action.type) {
    case 'INITIALIZE_CHAT':
      return { ...state, isInitialized: false, error: null };
    case 'INITIALIZE_SUCCESS':
      return { ...state, isInitialized: true, error: null };
    case 'INITIALIZE_ERROR':
      return { ...state, error: action.error, isInitialized: false };
    case 'OPEN_CHAT':
      return { ...state, isOpen: true, isMinimized: false, error: null };
    case 'CLOSE_CHAT':
      return { ...state, isOpen: false, isMinimized: false };
    case 'MINIMIZE_CHAT':
      return { ...state, isMinimized: true };
    case 'MAXIMIZE_CHAT':
      return { ...state, isMinimized: false };
    case 'SET_TYPING':
      return { ...state, isTyping: action.isTyping };
    case 'ADD_MESSAGE':
      return {
        ...state,
        messages: [...state.messages, action.message],
        error: null,
      };
    case 'SET_ERROR':
      return { ...state, error: action.error, isTyping: false };
    case 'CLEAR_ERROR':
      return { ...state, error: null };
    case 'SET_WORKFLOW':
      return { ...state, currentWorkflow: action.workflow };
    case 'UPDATE_PREFERENCES':
      return {
        ...state,
        preferences: {
          ...state.preferences,
          ...action.preferences,
        },
      };
    case 'START_WORKFLOW':
      return { ...state, currentWorkflow: action.workflow };
    default:
      return state;
  }
}

export function ChatProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(chatReducer, initialState);
  const [isInitialized, setIsInitialized] = useState(false);
  const chatServiceRef = useRef<ChatService>();
  const workflowManagerRef = useRef<WorkflowManager>();

  useEffect(() => {
    const initializeChat = async () => {
      try {
        chatServiceRef.current = new ChatService();
        workflowManagerRef.current = new WorkflowManager();
        setIsInitialized(true);
      } catch (error) {
        console.error('Failed to initialize chat:', error);
        setIsInitialized(true);
      }
    };

    initializeChat();
  }, []);

  const setActivityContext = useCallback((cityName: string, latitude: number, longitude: number) => {
    if (chatServiceRef.current) {
      chatServiceRef.current.setActivityContext({
        cityName,
        coordinates: { latitude, longitude }
      });
    }
  }, []);

  const toggleChat = useCallback(() => {
    dispatch({ type: state.isOpen ? 'CLOSE_CHAT' : 'OPEN_CHAT' });
  }, [state.isOpen]);

  const sendMessage = async (content: string, metadata?: Message['metadata']) => {
    if (!chatServiceRef.current) {
      if (import.meta.env.DEV) {
        chatServiceRef.current = new ChatService();
      } else {
        dispatch({ 
          type: 'SET_ERROR', 
          error: 'Chat service not initialized. Please try again.' 
        });
        return;
      }
    }

    try {
      dispatch({ type: 'CLEAR_ERROR' });
      dispatch({ type: 'SET_TYPING', isTyping: true });
      
      // Add user message
      const userMessage: Message = {
        id: Math.random().toString(36).substring(7),
        role: 'user',
        content,
        timestamp: Date.now(),
        metadata: {
          ...metadata,
          workflow: state.currentWorkflow,
        },
      };
      dispatch({ type: 'ADD_MESSAGE', message: userMessage });

      // Get AI response
      const response = await chatServiceRef.current.sendMessage(content);

      // Add AI response
      const assistantMessage: Message = {
        id: Math.random().toString(36).substring(7),
        role: 'assistant',
        content: response,
        timestamp: Date.now(),
        metadata: {
          workflow: state.currentWorkflow,
        },
      };
      dispatch({ type: 'ADD_MESSAGE', message: assistantMessage });

      // Update workflow if needed
      if (metadata?.workflow && 
          (!state.currentWorkflow || 
           metadata.workflow.type !== state.currentWorkflow.type)) {
        workflowManagerRef.current?.setWorkflowState(metadata.workflow);
        dispatch({ 
          type: 'SET_WORKFLOW', 
          workflow: metadata.workflow 
        });
      }

      // Update preferences if provided
      if (metadata?.preferences) {
        dispatch({ type: 'UPDATE_PREFERENCES', preferences: metadata.preferences });
      }
    } catch (error) {
      console.error('Chat error:', error);
      const errorMessage = error instanceof Error 
        ? error.message 
        : 'An unexpected error occurred while processing your message';
      dispatch({ type: 'SET_ERROR', error: errorMessage });
    } finally {
      dispatch({ type: 'SET_TYPING', isTyping: false });
    }
  };

  const value = useMemo(() => ({
    state,
    dispatch,
    sendMessage,
    setActivityContext,
    toggleChat
  }), [state, sendMessage, setActivityContext, toggleChat]);

  if (!isInitialized) {
    return null;
  }

  return (
    <ChatContext.Provider value={value}>
      {children}
    </ChatContext.Provider>
  );
}

export function useChat() {
  const context = useContext(ChatContext);
  if (!context) {
    console.warn('useChat must be used within a ChatProvider');
    return {
      state: {
        isOpen: false,
        isMinimized: false,
        isInitialized: false,
        isTyping: false,
        messages: [],
        error: null,
        currentWorkflow: undefined,
        preferences: {},
        activityContext: null
      },
      dispatch: () => {
        console.warn('Chat functionality is not available');
      },
      sendMessage: async () => {
        console.warn('Chat functionality is not available');
      },
      setActivityContext: () => {
        console.warn('Chat functionality is not available');
      },
      toggleChat: () => {
        console.warn('Chat functionality is not available');
      }
    } as ChatContextType;
  }
  return context;
} 