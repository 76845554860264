import { cleanUrl } from './url';

interface RedirectRule {
  pattern: RegExp;
  getRedirect: (matches: RegExpMatchArray) => string | null;
}

const redirectRules: RedirectRule[] = [
  // Handle /country/* to /destination/*
  {
    pattern: /^\/country\/([^/]+)$/i,
    getRedirect: (matches) => `/destination/${cleanUrl(matches[1])}`
  },

  // Handle /destinations/* to /destination/*
  {
    pattern: /^\/destinations\/([^/]+)$/i,
    getRedirect: (matches) => `/destination/${cleanUrl(matches[1])}`
  },

  // Handle old city-country-agent pattern to agent-city, but exclude content pages
  {
    pattern: /^\/travel-agents\/([^/]+)\/([^/]+)\/([^/]+)$/i,
    getRedirect: (matches) => {
      // Don't redirect if it's a content page
      if (['guide', 'top', 'best'].includes(matches[2].toLowerCase())) {
        return null;
      }
      const city = cleanUrl(matches[1]);
      const agent = cleanUrl(matches[3]);
      return `/travel-agents/${agent}/${city}`;
    }
  },

  // Handle malformed agent URLs with dashes
  {
    pattern: /^\/travel-agents-([^-]+)-([^-]+)$/i,
    getRedirect: (matches) => {
      const agent = cleanUrl(matches[1]);
      const city = cleanUrl(matches[2]);
      return `/travel-agents/${agent}/${city}`;
    }
  },

  // Handle incorrect casing in travel-agents
  {
    pattern: /^\/Travel-[Aa]gents(\/?.*)$/i,
    getRedirect: (matches) => `/travel-agents${cleanUrl(matches[1])}`
  },

  // Handle URLs with query parameters by stripping them
  {
    pattern: /^(\/(?:travel-agents|destinations?|country)\/[^?]+)\?.*$/i,
    getRedirect: (matches) => matches[1]
  }
];

// Valid URL patterns that should not redirect
const validPatterns = [
  /^\/travel-agents$/,  // directory page
  /^\/travel-agents\/[^/]+$/,  // city page
  /^\/travel-agents\/[^/]+\/[^/]+$/,  // agent profile
  /^\/travel-agents\/[^/]+\/(guide|top|best)\/[^/]+$/,  // city content pages
  /^\/destinations$/,  // destination page
  /^\/destination\/[^/]+$/  // destination country page
];

export function getRedirectPath(path: string): string | null {
  // Remove trailing slashes and clean the path
  const cleanPath = path.replace(/\/+$/, '');

  // Check if it's already a valid pattern
  for (const pattern of validPatterns) {
    if (pattern.test(cleanPath)) {
      return null;
    }
  }
  
  // Check redirect rules
  for (const rule of redirectRules) {
    const matches = cleanPath.match(rule.pattern);
    if (matches) {
      const redirectPath = rule.getRedirect(matches);
      // Skip if the rule returns null (no redirect needed)
      if (redirectPath === null) {
        continue;
      }
      // Verify the redirect path is valid
      for (const pattern of validPatterns) {
        if (pattern.test(redirectPath)) {
          return redirectPath;
        }
      }
    }
  }
  
  // If no valid redirect found, redirect to closest valid path
  const parts = cleanPath.split('/').filter(Boolean);
  if (parts[0]?.toLowerCase() === 'travel-agents') {
    if (parts.length === 1) return '/travel-agents';
    if (parts.length === 2) return `/travel-agents/${cleanUrl(parts[1])}`;
    // Check if it's a city content page
    if (parts.length === 4 && ['guide', 'top', 'best'].includes(parts[2])) {
      return `/travel-agents/${cleanUrl(parts[1])}/${parts[2]}/${cleanUrl(parts[3])}`;
    }
    // Default to agent profile pattern
    if (parts.length >= 3) return `/travel-agents/${cleanUrl(parts[1])}/${cleanUrl(parts[2])}`;
  }
  if (parts[0]?.toLowerCase() === 'destinations' || parts[0]?.toLowerCase() === 'destination') {
    if (parts.length === 1) return '/destinations';
    if (parts.length >= 2) return `/destination/${cleanUrl(parts[1])}`;
  }
  
  return null;
}

export function shouldRedirect(path: string): boolean {
  return getRedirectPath(path) !== null;
}

// Test function with comprehensive test cases
export function testRedirects() {
  const testCases = [
    // Valid URLs (should not redirect)
    '/travel-agents',
    '/travel-agents/chennai',
    '/travel-agents/beautiful-ways-viaggi/grosseto',
    '/travel-agents/istanbul/guide/best-places-to-visit',
    '/travel-agents/paris/top/10-attractions',
    '/travel-agents/london/best/hotels',
    '/destinations',
    '/destination/malaysia',

    // URLs that should redirect
    '/country/malaysia',
    '/destinations/malaysia',
    '/travel-agents/Grosseto/Italy/beautiful-ways-viaggi',
    '/Travel-Agents/chennai',
    '/travel-agents-beautiful-ways-viaggi-grosseto',
    '/travel-agents/chennai/india/agent-name',
    '/travel-agents/city/country/agent-name',
    
    // City content pages (should NOT redirect)
    '/travel-agents/istanbul/guide/places',
    '/travel-agents/paris/top/attractions',
    '/travel-agents/london/best/restaurants',
    '/travel-agents/new-york/guide/museums',
    
    // Invalid city content pages (should be cleaned but not redirected to agent profiles)
    '/travel-agents/istanbul/guides/places',
    '/travel-agents/paris/top-places',
    '/travel-agents/london/best-places/visit/extra',
    
    // URLs with query parameters
    '/travel-agents/chennai?q=test',
    '/destination/malaysia?param=value',
    '/travel-agents/istanbul/guide/places?param=value',
    
    // Special characters and spaces
    '/travel-agents/New York/USA/Agent Name',
    '/travel-agents/São Paulo/Brazil/Travel Expert',
    '/travel-agents/São Paulo/guide/Best Places to Visit',
    '/destination/São Tomé and Príncipe'
  ];

  console.log('\n🧪 Testing URL redirects:');
  testCases.forEach(url => {
    const redirectPath = getRedirectPath(url);
    console.log(`${url} → ${redirectPath || 'No redirect (valid URL)'}`);
  });
}

// Run tests in development
if (process.env.NODE_ENV === 'development') {
  testRedirects();
} 