export function cleanUrl(str: string): string {
  if (!str) return '';
  
  try {
    // Pre-clean the string before decoding
    let clean = str
      .replace(/&/g, 'and')  // Replace & with 'and'
      .replace(/[|]/g, '-')  // Replace | with hyphen
      .replace(/\s+/g, '-')  // Replace spaces with hyphens
      .replace(/%20/g, '-'); // Replace %20 with hyphen
    
    // Try to decode any remaining URL encoded characters
    try {
      clean = decodeURIComponent(clean);
    } catch (e) {
      // If decoding fails, continue with original string
      console.warn('Failed to decode URL (continuing with original):', str);
    }
    
    // Remove diacritics and normalize special characters
    clean = clean.normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')  // Remove combining diacritics
      .replace(/[đĐ]/g, 'd')            // Handle specific characters
      .replace(/[łŁ]/g, 'l')
      .replace(/[øØ]/g, 'o')
      .replace(/[ıİ]/g, 'i');
    
    // Convert to URL-friendly format
    clean = clean
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9-]/g, '-')     // Replace non-alphanumeric chars with hyphens
      .replace(/-+/g, '-')            // Collapse multiple hyphens
      .replace(/^-+|-+$/g, '');       // Remove leading/trailing hyphens
    
    return clean;
    
  } catch (error) {
    console.warn(`Warning: Failed to clean URL "${str}"`, error);
    // Fallback to basic cleaning
    return str
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-+|-+$/g, '');
  }
}

// Test URLs
const testUrls = [
  'jersey%20city',
  'new%20delhi',
  'george%20town',
  'kuala%20lumpur',
  'bayan%20lepas',
  'mustafapa%C5%9Fa%20k%C3%B6y%C3%BC',
  '%C5%9Fi%C5%9Fli',
  'i%CC%87zmir',
  'kuşadası',
  'gaziosmanpaşa'
];

// Run tests in development
if (process.env.NODE_ENV === 'development') {
  console.log('\n🧪 Testing URL cleaning:');
  testUrls.forEach(url => {
    console.log(`${url} → ${cleanUrl(url)}`);
  });
}