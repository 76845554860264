import { Link } from 'react-router-dom';
import { Globe, Users, Building2, Sparkles, MessageSquare, X, Home, LogIn } from 'lucide-react';
import { useChat } from '../contexts/ChatContext';
import ChatInterface from './chat/ChatInterface';
import { cn } from '../lib/utils';
import { FloatingDock } from './ui/floating-dock';
import { Suspense, useEffect, useState } from 'react';

function NavigationContent() {
  const { state, dispatch } = useChat();
  const [isClient, setIsClient] = useState(false);
  
  useEffect(() => {
    setIsClient(true);
  }, []);

  const handleChatClick = () => {
    if (state.isOpen) {
      dispatch({ type: 'CLOSE_CHAT' });
    } else {
      dispatch({ type: 'OPEN_CHAT' });
    }
  };

  const navigationItems = [
    { title: 'Home', icon: <Home className="h-full w-full text-neutral-500 dark:text-neutral-300" />, href: '/' },
    { title: 'Global Map', icon: <Globe className="h-full w-full text-neutral-500 dark:text-neutral-300" />, href: '/map' },
    { title: 'Find Agents', icon: <Users className="h-full w-full text-neutral-500 dark:text-neutral-300" />, href: '/travel-agents' },
    { title: 'Hotel Marketing', icon: <Building2 className="h-full w-full text-neutral-500 dark:text-neutral-300" />, href: '/hotel-marketing' },
    { title: 'Expo', icon: <Sparkles className="h-full w-full text-neutral-500 dark:text-neutral-300" />, href: '/expo' },
    ...(isClient ? [{
      title: state.isOpen ? 'Close Marco AI' : 'Marco AI',
      icon: state.isOpen ? 
        <X className="h-full w-full text-neutral-500 dark:text-neutral-300" /> : 
        <MessageSquare className="h-full w-full text-neutral-500 dark:text-neutral-300" />,
      href: '#',
      onClick: handleChatClick
    }] : []),
    { title: 'Login', icon: <LogIn className="h-full w-full text-neutral-500 dark:text-neutral-300" />, href: '/login' },
  ];

  return (
    <>
      <FloatingDock 
        items={navigationItems}
        desktopClassName="fixed bottom-8 left-1/2 -translate-x-1/2 z-50"
        mobileClassName="fixed bottom-8 right-8 z-50"
      />
      {isClient && <ChatInterface />}
    </>
  );
}

export default function Navigation() {
  return (
    <Suspense fallback={
      <FloatingDock 
        items={[
          { title: 'Home', icon: <Home className="h-full w-full text-neutral-500 dark:text-neutral-300" />, href: '/' },
          { title: 'Login', icon: <LogIn className="h-full w-full text-neutral-500 dark:text-neutral-300" />, href: '/login' },
        ]}
        desktopClassName="fixed bottom-8 left-1/2 -translate-x-1/2 z-50"
        mobileClassName="fixed bottom-8 right-8 z-50"
      />
    }>
      <NavigationContent />
    </Suspense>
  );
}