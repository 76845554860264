import React, { useState, FormEvent } from 'react';
import { submitUrlsToIndexNow, generateApiKey, getKeyFileInstructions } from '../services/indexNow';

export default function IndexNow() {
  const [urls, setUrls] = useState<string>('');
  const [apiKey, setApiKey] = useState<string>('');
  const [host, setHost] = useState<string>('');
  const [keyLocation, setKeyLocation] = useState<string>('');
  const [status, setStatus] = useState<{ success?: boolean; message?: string }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);

  const handleGenerateKey = () => {
    const newKey = generateApiKey();
    setApiKey(newKey);
    if (host) {
      setKeyLocation(`https://${host}/${newKey}.txt`);
    }
    setShowInstructions(true);
  };

  const handleHostChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newHost = e.target.value;
    setHost(newHost);
    if (apiKey && newHost) {
      setKeyLocation(`https://${newHost}/${apiKey}.txt`);
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setStatus({});

    try {
      // Basic validation
      if (!host.trim()) {
        throw new Error('Host domain is required');
      }
      if (!apiKey.trim()) {
        throw new Error('API key is required');
      }
      if (!keyLocation.trim()) {
        throw new Error('Key location is required');
      }

      const urlList = urls.split('\n')
        .map(url => url.trim())
        .filter(url => url);

      if (!urlList.length) {
        throw new Error('Please enter at least one URL');
      }

      // Validate URLs
      urlList.forEach(url => {
        try {
          new URL(url);
        } catch {
          throw new Error(`Invalid URL format: ${url}`);
        }
      });

      const response = await submitUrlsToIndexNow(urlList, apiKey, host, keyLocation);
      setStatus(response);
    } catch (error) {
      setStatus({
        success: false,
        message: error instanceof Error ? error.message : 'An error occurred'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">IndexNow URL Submission</h1>
      
      <div className="mb-6 p-4 bg-blue-50 text-blue-700 rounded">
        <p>Submit your URLs to be indexed by search engines using the IndexNow protocol.</p>
        <p className="mt-2 text-sm">Make sure you have:</p>
        <ul className="list-disc ml-6 mt-1 text-sm">
          <li>A valid API key file hosted on your domain</li>
          <li>The correct host domain name</li>
          <li>Valid URLs that belong to your domain</li>
        </ul>
      </div>
      
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="host" className="block text-sm font-medium mb-1">
            Host Domain
          </label>
          <input
            type="text"
            id="host"
            value={host}
            onChange={handleHostChange}
            placeholder="example.com"
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
          <p className="mt-1 text-sm text-gray-500">Enter your domain name without http:// or https://</p>
        </div>

        <div>
          <label htmlFor="apiKey" className="block text-sm font-medium mb-1">
            API Key
          </label>
          <div className="flex gap-2">
            <input
              type="text"
              id="apiKey"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              className="flex-1 p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            />
            <button
              type="button"
              onClick={handleGenerateKey}
              className="px-4 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200 focus:ring-2 focus:ring-gray-500"
            >
              Generate Key
            </button>
          </div>
          <p className="mt-1 text-sm text-gray-500">Your IndexNow API key</p>
        </div>

        <div>
          <label htmlFor="keyLocation" className="block text-sm font-medium mb-1">
            Key Location
          </label>
          <input
            type="text"
            id="keyLocation"
            value={keyLocation}
            onChange={(e) => setKeyLocation(e.target.value)}
            placeholder="https://example.com/key.txt"
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
          <p className="mt-1 text-sm text-gray-500">The full URL where your API key file is hosted</p>
        </div>

        {showInstructions && apiKey && host && (
          <div className="p-4 bg-yellow-50 text-yellow-700 rounded border border-yellow-200">
            <h3 className="font-medium mb-2">Key File Instructions</h3>
            <pre className="whitespace-pre-wrap text-sm font-mono bg-yellow-100 p-3 rounded">
              {getKeyFileInstructions(apiKey, host)}
            </pre>
          </div>
        )}

        <div>
          <label htmlFor="urls" className="block text-sm font-medium mb-1">
            URLs (one per line)
          </label>
          <textarea
            id="urls"
            value={urls}
            onChange={(e) => setUrls(e.target.value)}
            placeholder="https://example.com/page1&#10;https://example.com/page2"
            className="w-full p-2 border rounded h-32 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
          <p className="mt-1 text-sm text-gray-500">Enter each URL on a new line</p>
        </div>

        <button
          type="submit"
          disabled={isLoading}
          className={`w-full p-2 text-white rounded transition-colors ${
            isLoading
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
          }`}
        >
          {isLoading ? (
            <span className="flex items-center justify-center">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Submitting...
            </span>
          ) : 'Submit URLs'}
        </button>
      </form>

      {status.message && (
        <div
          className={`mt-4 p-4 rounded ${
            status.success
              ? 'bg-green-50 text-green-700 border border-green-200'
              : 'bg-red-50 text-red-700 border border-red-200'
          }`}
        >
          {status.message}
        </div>
      )}
    </div>
  );
} 